@media (max-width : 1023px) {
  .footer {
    height        : 88px;
    border-bottom : 6px solid #f60e0e;

    .hide-mobile {
      display : none;
    }

    .copyright {
      font-size   : 9px;
      width       : 240px;
      top         : 20px;
      left        : 50%;
      margin-left : -120px;
    }

    .designer {
      font-size : 11px;
    }

    .logo-and-designer {
      text-align  : center;
      top         : 40px;
      right       : auto;
      left        : 50%;
      width       : 160px;
      margin-left : -80px;
    }
  }

  .overlay {
    background : rgba(0, 0, 0, .8);
    position   : absolute;
    top        : 0;
    right      : 0;
    bottom     : 0;
    left       : 0;
    transition : all .4s;
    opacity    : 0;
    z-index    : -1;
  }
}

.menu-open {
  overflow : hidden;
  position : fixed;
  top      : 0;
  right    : 0;
  bottom   : 0;
  left     : 0;

  .overlay {
    z-index    : 1;
    opacity    : 1;
    transition : all .4s;
  }
}