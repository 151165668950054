@media (max-width : 1023px) {
  .about-slider {
    height        : 470px;
    margin-bottom : 35px;

    .carousel-cell {
      height : 470px;
    }

    .about {
      &__title {
        font-size : 25px;
        padding   : 20px 0;
      }

      &__statistics-item {
        width : 135px;
      }

      &__title-quantity-wrapper {
        height        : 94px;
        margin-bottom : 10px;

        &:after {
          height : 3px;
          width  : 42px;
        }
      }

      &__statistics-item-quantity {
        font-size     : 40px;
        margin-bottom : 10px;

        img {
          max-width : 30%;
        }
      }

      &__statistics-item-title, &__statistics-item-description {
        font-size : 12px;
      }

      &__podrobnee-link {
        bottom : 20px;
        left   : 5px;
      }
    }
  }
}