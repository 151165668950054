@media (max-width : 1023px) {
  .catalog {
    padding-top : 80px;

    .fixed {
      left    : 8px;
      right   : 8px;
      z-index : 2;
      width   : auto;
    }

    .header-page {
      height  : auto;
      padding : 20px;

      &__logo {
        margin-bottom : 10px;
        width         : 180px;
        line-height   : 75px;
      }

      &__title {
        width     : auto;
        font-size : 20px;
      }

      &-page {
        height  : auto;
        padding : 20px;
      }

      &__link {
        top   : -57px;
        right : auto;
        left  : 0;
      }
    }

    &__system-img {
      img {
        max-width : 100%;
      }
    }

    &__characteristics-title {
      font-size : 14px;
    }

    .little {
      padding : 20px 14px 0;
    }

    .catalog__link-mail {
      &:after {
        bottom : 0;
        width  : 89%;
      }
    }
  }

  .catalog__page {
    &-info-title {
      width         : 100%;
      margin-bottom : 10px;
      padding       : 0;
      font-size     : 18px;

      &-img {
        width        : 60px;
        height       : 60px;
        position     : initial;
        float        : left;
        margin-right : 20px;
      }
    }

    &-info-description {
      width   : 100%;
      padding : 0;
    }
  }

  .embed-responsive {
    width : 100%;
  }

  .links-wrapper {
    width : auto;
  }

  .catalog__link_blue {
    margin-bottom : 20px;

    &:before {
      display : none;
    }
  }
}
