.catalog-page {
  margin-top : 50px;

  .about-us-content__title-description {
    padding-left : 100px;

    &:before {
      left : 0;
    }
  }

  .main-h2 {
    margin-bottom : 50px;
  }

  &__item {
    box-shadow    : 0 1px 10px #dadadb;
    border-radius : 3px;
    overflow      : hidden;
    margin-bottom : 40px;

    .catalog__link_blue {
      span {
        &:after {
          bottom : 0;
        }
      }
    }
  }

  &__item-logo {
    margin-bottom : 20px;
    width         : 200px;

    img {
      max-width : 100%;
    }
  }

  &__item-icon {
    position         : absolute;
    top              : 30px;
    left             : 40px;
    box-shadow       : 0 2px 2px rgba(0, 0, 0, 0.1);
    border-radius    : 5px;
    background-color : #0054af;
    width            : 97px;
    height           : 97px;
    display          : flex;
    align-items      : center;
    justify-content  : center;
    background-image : linear-gradient(to top, #00458f 0%, #0054af 100%);

    img {
      width : 50%;
    }
  }

  &__item-img {
    position            : absolute;
    top                 : 0;
    right               : 5px;
    bottom              : 0;
    left                : 0;
    background-position : center !important;
    background-size     : cover !important;

    &-wrapper {
      position : relative;
    }
  }

  &__item-title {
    color          : #00458f;
    font-family    : $GothamPro;
    font-size      : 20px;
    font-weight    : 500;
    text-transform : uppercase;
    margin-bottom  : 6px;
  }

  &__item-sub-title {
    font-family   : $GothamPro;
    font-weight   : 500;
    color         : #46474a;
    font-size     : 16px;
    margin-bottom : 24px;
  }

  &__item-description {
    color         : #46474a;
    font-family   : $GothamPro;
    font-size     : 14px;
    font-weight   : 400;
    line-height   : 20px;
    margin-bottom : 38px;
  }

  .info-wrapper {
    padding : 20px;
  }
}