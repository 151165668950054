.about-us-content {
  padding-top : 50px;

  .about-slider {
    margin-top : 50px;
  }

  .col {
    &:first-child {
      padding-left : 96px;
    }
  }

  &__title-description {
    color         : #00458f;
    font-family   : $GothamProMedium;
    font-size     : 22px;
    font-weight   : 500;
    line-height   : 26px;
    margin-bottom : 36px;
    position      : relative;
    padding-left  : 90px;

    &:before {
      content             : '';
      background          : url("/build/img/icon/ico-check.svg");
      width               : 60px;
      height              : 60px;
      position            : absolute;
      top                 : 8px;
      left                : 0;
      background-position : center;
      background-repeat   : no-repeat;
    }
  }

  &__p {
    color         : #46474a;
    font-family   : $GothamPro;
    font-size     : 14px;
    font-weight   : 400;
    line-height   : 24px;
    margin-bottom : 32px;

    &:last-child {
      margin-bottom : 0;
    }
  }

  &__description {
    @extend .about-us-content__p;
    font-size   : 16px;
    font-family : $GothamProMedium;
  }

  &__ul {
    margin     : 0;
    padding    : 0;
    list-style : none;

    li {
      color         : #00458f;
      font-family   : $GothamProMedium;
      font-size     : 16px;
      font-weight   : 500;
      margin-bottom : 22px;
      position      : relative;

      &:before {
        content          : '';
        position         : absolute;
        height           : 2px;
        background-color : #dadadb;
        width            : 28px;
        top              : 10px;
        left             : -42px;
      }

      &:last-child {
        margin : 0;
      }
    }
  }

  // ДЛЯ ГАЛЕРИИ СЕРТИФИКАТОВ
  .qualities-iso {
    color         : #46474a;
    font-family   : $GothamPro;
    font-size     : 18px;
    font-weight   : 200;
    width         : 35%;
    padding-right : 50px;
    position      : relative;

    p {
      font-size : 14px;
    }

    &:before {
      content         : '';
      background      : url("/build/img/icon/ico-iso-big-red.svg");
      position        : absolute;
      height          : 60px;
      width           : 60px;
      background-size : cover;
      top             : 4px;
      left            : -95px;
    }
  }

}

.certificate {
  &__title-info-wrapper {
    padding-left  : 80px;
    margin-bottom : 50px;
    width         : 50%;

    p {
      width       : 458px;
      line-height : 26px;
    }
  }

  &__item {
    width  : 240px;
    margin : 0 20px 68px 20px;
  }

  &__img {
    margin-bottom : 24px;
    min-height    : 270px;

    a {
      display       : block;
      border        : solid 2px #dadadb;
      border-radius : 3px;
      overflow      : hidden;
      width         : 194px;
      box-shadow    : 0 1px 10px #dadadb;
      transition    : all .3s;

      &:after {
        content    : '';
        position   : absolute;
        background : url("/build/img/icon/ico-full.svg");
        width      : 40px;
        height     : 40px;
        top        : 50%;
        left       : 50%;
        margin     : -20px 0 0 -20px;
        opacity    : 0;
        transition : all .2s;
      }

      &:focus, &:active {
        outline : none;
      }

      img {
        width : 100%;
      }
    }
  }

  &__title-img-wrapper {
    width : 194px;

    &:hover {
      .certificate__img {
        a {
          border   : solid 2px #034790;
          position : relative;

          &:after {
            content    : '';
            position   : absolute;
            background : url("/build/img/icon/ico-full.svg");
            width      : 40px;
            height     : 40px;
            top        : 50%;
            left       : 50%;
            margin     : -20px 0 0 -20px;
            opacity    : 1;
          }

          &:before {
            content    : '';
            position   : absolute;
            top        : 0;
            right      : 0;
            bottom     : 0;
            left       : 0;
            background : rgba(3, 71, 144, .3);
          }
        }
      }

      .certificate__title {
        color : #00458f;
      }
    }
  }

  &__title {
    color         : #46474a;
    font-family   : $GothamPro;
    font-size     : 18px;
    font-weight   : 500;
    min-height    : 52px;
    margin-bottom : 38px;
    position      : relative;
    transition    : all .3s;

    &:after {
      content          : '';
      width            : 55px;
      height           : 3px;
      background-color : #000000;
      opacity          : 0.2;
      position         : absolute;
      bottom           : -20px;
      left             : 0;
    }
  }

  &__description {
    color       : #565656;
    font-family : $GothamPro;
    font-size   : 14px;
    font-weight : 400;
    height      : 62px;
  }
}

// ДЛЯ ФОНА ЗАГОЛОВКА СТРАНИЦ
.main-h1_white-wrapper {
  height        : 220px;
  border-radius : 3px;
  padding-left  : 40px;
  margin-bottom : 30px;
}

// ДЛЯ ТАБОВ
.nav-pills {
  margin-bottom : 46px;
}

.nav-item {
  margin-right : 20px;

  &:last-child {
    margin : 0;
  }
}

.nav-link {
  font-family    : $GothamPro;
  font-size      : 18px;
  font-weight    : 500;
  text-transform : uppercase;
  color          : #46474a;
  transition     : all .3s;
  border-radius  : 5px;
  border         : 2px solid transparent;

  //fd

  span {
    position   : relative;
    transition : all .3s;

    &:after {
      content          : '';
      height           : 2px;
      background-color : #46474a;
      opacity          : 0.2;
      width            : 100%;
      bottom           : -2px;
      left             : 0;
      position         : absolute;
    }
  }
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  background    : transparent;
  color         : #d9261c;
  border-radius : 5px;
  border        : 2px solid #00458f;

  span {
    position   : relative;
    transition : all .3s;

    &:after {
      content          : '';
      height           : 2px;
      background-color : #46474a;
      opacity          : 0;
      width            : 100%;
      left             : 0;
      position         : absolute;
    }
  }
}