@media (max-width : 1023px) {
  .clients-page {
    padding-top : 20px;

    &__item {
      padding       : 0 20px;
      margin-bottom : 20px;
    }

    .client-item__title {
      padding-top : 20px;
      height      : auto;

      &:before {
        left  : 0;
        right : 0;
      }
    }

    .about-us-content__title-description {
      margin-bottom : 20px;
      padding : 0;
    }
  }
}