@media (max-width : 1023px) {
  .project-item-page {
    margin-top : 10px !important;

    .padding-offset-left-30px, .padding-0 {
      padding : 0 15px;
    }
  }

  .projects-page {
    padding-top : 20px;
  }
}