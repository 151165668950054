// ДЛЯ ПОЗИЦИОНИРОВАНИЯ ПОИНТОВ
@mixin position($top,$right,$bottom,$left) {
  top    : $top;
  right  : $right;
  bottom : $bottom;
  left   : $left;
}

// ДЛЯ КАТРЫ ПРОЕКТОВ
.projects-map {
  height   : 270px;
  position : relative;

  .main-h2 {
    position : relative;
    z-index  : 12;
    width    : 440px;
  }

  &_bottom-gray {
    height           : 420px;
    background-color : rgba(193, 196, 200, .3);
    margin-bottom    : 50px;
    position         : relative;
  }

  .active-point {
    &:before {
      opacity : 1;
    }
  }

  // САМА КАРТА И ПУНКТЫ
  &__points {
    height     : 572px;
    width      : 948px;
    margin     : 0 auto;
    //background : red;
    //opacity    : .2;
    position   : relative;
    top        : -68px;
    z-index    : 2;
    background : url("/build/img/map-img.png") center, no-repeat;
  }

  &__point {
    width            : 16px;
    height           : 16px;
    background-color : #fdfdfd;
    border-radius    : 50%;
    background-image : linear-gradient(to top, #d8d8d8 0%, #fdfdfd 100%);
    position         : absolute !important;
    box-shadow       : -1px 3px 5px rgba(0, 0, 0, .1);
    cursor           : pointer;
    transition       : all .9s;

    &:before {
      content          : '';
      width            : 10px;
      height           : 10px;
      border-radius    : 50px;
      background       : red;
      background-image : linear-gradient(to top, #d9261c 0%, #ff0000 100%);
      position         : absolute;
      left             : 50%;
      margin-left      : -5px;
      top              : 50%;
      margin-top       : -5px;
      transition       : all .2s;
      opacity          : 0;
    }

    &:after {
      content       : '';
      position      : absolute;
      width         : 16px;
      height        : 16px;
      border        : 1px solid rgba(255, 255, 255, 0);
      border-radius : 50%;
      transition    : all .2s;
    }

    &:hover {
      &:before {
        opacity : 1;
      }

      &:after {
        width  : 30px;
        height : 30px;
        margin : -7px 0 0 -7px;
        border : 1px solid rgba(255, 255, 255, .5);
      }
    }
  }

  &__infoblock {
    position : absolute;
    bottom   : 26px;
  }

  &__podronee {
    background-color : #0054af;
    background-image : linear-gradient(to top, #00458f 0%, #0054af 100%);
    @extend .oborydovanie__link;
    margin           : 0;

    &:hover {
      &:before {
        opacity : 1;
      }
    }
  }

  &__title-project {
    color         : #d9261c;
    font-family   : $GothamPro;
    font-size     : 22px;
    font-weight   : 500;
    margin-bottom : 36px;
    position      : relative;

    &:after {
      content          : '';
      position         : absolute;
      width            : 55px;
      height           : 3px;
      background-color : #000000;
      opacity          : 0.2;
      left             : 0;
      bottom           : -20px;
    }
  }

  &__title-project-description {
    color         : #46474a;
    font-family   : $GothamPro;
    font-size     : 14px;
    font-weight   : 400;
    margin-bottom : 14px;
  }
}

