.project-item-page {
  margin-top : 50px;
}

.project-item {

  &__img {
    position : relative;

    img {
      width : 100%;
    }

    .date {
      position         : absolute;
      border-radius    : 3px;
      background-color : #ffffff;
      color            : #46474a;
      font-family      : $GothamPro;
      font-size        : 14px;
      font-weight      : 400;
      bottom           : 20px;
      left             : 30px;
      padding          : 6px 10px;
    }
  }

  &__info-wrapper {
    box-shadow    : 0 1px 10px #dadadb;
    padding       : 30px !important;
    border-radius : 0 0 3px 3px;
    margin-bottom : 40px;
  }

  &__title {
    padding-bottom : 26px;
    border-bottom  : solid 2px rgba(0, 0, 0, .1);
    margin-bottom  : 30px;
  }

  &__description {
    color         : #46474a;
    font-family   : $GothamProMedium;
    font-size     : 16px;
    font-weight   : 500;
    line-height   : 20px;
    margin-bottom : 26px;
  }

  &__p {
    color         : #46474a;
    font-family   : $GothamPro;
    font-size     : 14px;
    font-weight   : 400;
    line-height   : 20px;
    margin-bottom : 24px;
  }

  .main-h3_blue-little {
    color          : #00458f;
    font-family    : $GothamProMedium;
    font-size      : 16px;
    font-weight    : 500;
    text-transform : uppercase;
    margin-bottom  : 26px;
  }
}