@font-face{

  font-family: 'GothamPro';

  src: url('/fonts/GothaProReg.otf');

  font-weight: normal;

  font-style: normal;

}

@font-face{

  font-family: 'GothamProMedium';

  src: url('/fonts/GothaProMed.otf');

  font-weight: normal;

  font-style: normal;

}

$GothamPro: font-family 'GothamPro', sans-serif;

$GothamProMedium: font-family 'GothamProMedium', sans-serif;