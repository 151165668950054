@media (max-width : 1023px) {
  .main-slider {
    margin-bottom : 25px;
    padding-top   : 20px !important;

    .carousel {
      &-cell {
        background-size     : contain;
        background-position : top center !important;
      }

      .flickity-page-dots {
        bottom : 10px;
      }
    }

    .flickity-prev-next-button {
      top     : 94%;
      border  : 1px solid rgba(255, 255, 255, .5);
      z-index : 1;

      .flickity-button-icon {
        fill : white;
      }
    }
  }

  .carousel {
    .flickity-page-dots {
      margin-left : 8px;

      .dot {
        height : 14px;
        width  : 14px;
      }
    }

    .flickity-prev-next-button.next {
      right : 5px;
    }

    .flickity-prev-next-button.previous {
      left  : auto;
      right : 45px !important;
    }
  }

  .banner {
    padding-top : 230px;

    .image {
      background-size : cover !important;
      position        : absolute;
      right           : 0;
      top             : 0;
      bottom          : 0;
      width           : 360px;

      img{
        max-width: 100%;
      }
    }

    &__info-block {
      padding : 0 40px 0 15px;
    }

    &__title {
      font-size     : 20px;
      margin-bottom : 20px;

      &:after {
        bottom : -10px;
      }
    }

    &__description {
      font-size     : 12px;
      margin-bottom : 20px;
    }

    &__link {
      font-size     : 12px;
      padding       : 8px 14px;
      margin-bottom : 10px;
    }
  }
}