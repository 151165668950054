// ДЛЯ ЗАГОЛОВКОВ
.main {
  &-h1 {
    font-family    : $GothamPro;
    font-size      : 35px;
    text-transform : uppercase;
    margin         : 0;
    position       : relative;
    padding-left   : 16px;

    &_white {
      @extend .main-h1;
      color : #ffffff;
    }

    &_blue {
      @extend .main-h1;
      color : #00458f;
    }

    &:before {
      content          : '';
      position         : absolute;
      width            : 3px;
      background-color : #d9261c;
      top              : 5px;
      bottom           : 5px;
      left             : 0;
    }
  }

  &-h2 {
    color          : #00458f;
    font-family    : $GothamPro;
    font-size      : 28px;
    font-weight    : 500;
    text-transform : uppercase;
    margin         : 0;
    position       : relative;
    padding-left   : 16px;

    &:before {
      content          : '';
      position         : absolute;
      width            : 3px;
      background-color : #d9261c;
      top              : 5px;
      bottom           : 5px;
      left             : 0;
    }
  }

  &-h3 {
    font-family    : $GothamProMedium;
    font-size      : 20px;
    font-weight    : 500;
    text-transform : uppercase;

    &_blue {
      @extend .main-h3;
      color : #00458f;
    }

    &_notrmal-trans {
      text-transform : none;
    }

    &_bottom-offset-20 {
      margin-bottom : 20px;
    }
  }
}

.padding {
  &-0 {
    padding : 0;
  }
}

@media (max-width : 1023px) {
  .main-h2 {
    font-size : 21px;
  }
}
