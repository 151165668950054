// ДЛЯ МОБИЛЬНОЙ ВЕРСИИ
@media (max-width : 1023px) {
  html {
    overflow-x : hidden;
  }

  .header {
    margin-bottom : 0;

    .logo {
      width   : 100px;
      display : block;

      img {
        max-width : 100%;
      }
    }

    &:after {
      height : auto;
      top    : 45px;
    }

    .contact-menu__lang-menu-wrapper {
      width : auto;
    }
  }

  .mobile-menu-trigger {
    position : relative;
    z-index  : 2;
    right    : -10px;
  }

  .select-lang-wrapper {
    box-shadow       : 0 1px 1px rgba(0, 0, 0, 0.1);
    background-color : #f5f5f5;
    padding          : 20px 0 35px 0;
    position         : relative;

    &:after {
      content          : '';
      height           : 2px;
      background-color : #bababa;
      opacity          : .25;
      position         : absolute;
      top              : 74px;
      width            : 100%;
    }

    .select-lang {
      .dropdown-toggle {
        border      : 2px solid rgba(0, 82, 170, .5);
        margin-left : 25px;
        font-size   : 13px;

        &:after {
          content             : '';
          background          : url('../img/icon/arrow-bottom-blue.svg');
          height              : 10px;
          width               : 12px;
          background-size     : contain;
          background-position : center;
          background-repeat   : no-repeat;
        }
      }
    }

    .contact-menu {
      margin : 30px 0 0 20px;

      li {
        margin-bottom : 16px;
        font-size     : 13px;

        &:nth-child(2) {
          border : none;
        }

        &:last-child {
          margin    : 0;
          position  : relative;
          font-size : 10px;
        }
      }

      a {
        font-size : 14px;

        &:after {
          display : none;
        }

        span {
          position : relative;

          &:after {
            content          : '';
            background-color : #d9261c;
            opacity          : .4;
            height           : 1px;
            position         : absolute;
            right            : 0;
            bottom           : 2px;
            width            : 100%;
          }
        }
      }
    }
  }
}

@media (max-width : 1023px) {
  .header {
    .logo {
      img {
        max-width : 100%;
      }
    }

    .df--jc-sb {
      align-items : center;
    }
  }

  // ДЛЯ МЕНЮ КОНТАКТОВ
  .contact-menu {
    margin          : 10px 0 0 0;
    justify-content : space-around;

    li {
      padding : 0;
    }

    a {
      font-size : 12px;
    }

    &__icon {
      width        : 14px;
      margin-right : 10px;

      img {
        max-width : 100%;
      }
    }
  }

  .description-select-lang-wrapper {
    padding : 0;
  }

  // ДЛЯ ОПИСАНИЯ В HEADER
  .description {
    width  : 200px;
    margin : 10px 0 0 0;
  }

  // ДЛЯ МОБИЛЬНОГО МЕНЮ
  .mobile-menu-wrapper {
    transition       : all .4s ease-in-out;
    transform        : translate(130%);
    position         : fixed;
    top              : 0;
    right            : 60px;
    bottom           : 0;
    left             : 0;
    box-shadow       : 0 2px 2px rgba(0, 0, 0, 0.1);
    background-color : #545d66;
    background-image : linear-gradient(to top, #00458f 0%, #0054af 100%);
    z-index          : 10;

    .main-menu {
      display       : block;
      border-radius : 0;
      transform     : translate(0);

      &__title {
        color       : #ffffff;
        font-family : $GothamPro;
        font-size   : 12px;
        font-weight : 400;
        padding     : 14px 0 14px 27px;
      }

      li {
        border-bottom : solid 1px rgba(186, 186, 186, .25);
        position      : relative;

        &:before {
          content             : '';
          background          : url('../img/icon/arrow-bottom.svg');
          height              : 10px;
          width               : 16px;
          background-position : center;
          background-size     : cover;
          vertical-align      : baseline;
          position            : absolute;
          top                 : 50%;
          right               : 20px;
          margin-top          : -8px;
          transform           : rotate(-90deg);
          z-index             : 1;
        }

        &:first-child {
          border-radius : 0;
          border-top    : solid 1px rgba(186, 186, 186, .25);
        }

        &:last-child {
          border-radius : 0;
        }
      }

      a {
        font-size : 14px;
      }
    }

    &:after {
      content : '';
    }

    //  ДЛЯ КНОПКИ МЕНЮ
    .mobile-menu-trigger {
      transform : translate(7px);

    }
  }

  .mobile-submenu {
    display : block;
  }
}

// ДЛЯ ОТКРЫТОГО МЕНЮ
.menu-open {
  .mobile-menu-wrapper {
    transform      : translate(0);
    overflow       : auto;
    padding-bottom : 26px;
  }
}

// ДЛЯ НИЖНИХ БЛОКОВ В МОБИЛЬНОМ МЕНЮ
.mobile-menu-wrapper {
  .developer-copyright-mobile {
    margin   : 44px 0 0 0;
    position : relative;

    &:before {
      content    : '';
      height     : 2px;
      background : rgba(255, 255, 255, .25);
      position   : absolute;
      width      : 100%;
      top        : -25px;
    }
  }

  .iso-menu {
    margin : 30px 0 0 20px;

    li {
      font-size   : 13px;
      display     : flex;
      align-items : flex-start;
    }

    &:after {
      display : none;
    }

    i {
      padding-top : 2px;
    }
  }

  .copyright {
    position     : initial;
    font-size    : 11px;
    padding-left : 30px;
  }

  .designer {
    font-size    : 11px;
    padding-left : 30px;

    a {
      font-size    : 11px;
      padding-left : 5px;
    }
  }
}
