.contacts-page {
  padding : 50px 0;

  .main-h2 {
    margin-bottom : 30px;
  }

  .fon_white {
    box-shadow    : 0 3px 4px rgba(0, 0, 0, .1);
    border-radius : 3px;
  }

  .catalog__page-info-title {
    padding        : 0;
    text-transform : uppercase;
    font-size      : 18px;
    margin-bottom  : 20px;
    width          : 100%;
    line-height    : normal;
  }

  .row {
    margin : 0;
  }

  .border {
    &-right {
      padding : 30px;
    }

    &-left {
      padding : 30px 40px;
    }
  }
}

.contact {
  &__ul {
    padding    : 0;
    margin     : 0 0 20px 0;
    list-style : none;

    li {
      margin-bottom : 20px;

      &:last-child {
        margin : 0;
      }
    }
  }

  &__title {
    color       : #46474a;
    font-family : $GothamPro;
    font-size   : 16px;
    font-weight : 400;
    padding     : 0 0 0 25px;
    position    : relative;
  }

  &__icon {
    position : absolute;
    top      : -1px;
    left     : 0;
  }

  &__adres {
    color         : #00458f;
    font-family   : $GothamPro;
    font-size     : 16px;
    font-weight   : 500;
    line-height   : normal;
    padding-right : 80px;

    &-link {
      @extend .contact__adres;
      padding : 0;
    }
  }

  &__link-mail {
    color       : #00458f;
    font-family : $GothamPro;
    font-size   : 16px;
    font-weight : 500;
    line-height : normal;
    position    : relative;

    &:after {
      content          : '';
      background-color : #d9261c;
      opacity          : .4;
      height           : 1px;
      position         : absolute;
      right            : 0;
      bottom           : 0;
      left             : 0;
    }
  }

  &__form {
    width           : 500px;
    margin          : 0 auto;
    display         : flex;
    flex-wrap       : wrap;
    justify-content : space-between;

    input {
      height        : 40px;
      border-radius : 4px;
      box-shadow    : inset 1px 2px 3px rgba(24, 24, 24, 0.05);
      border        : 1px solid rgba(84, 93, 102, 0.5);
      margin-bottom : 18px;
      width         : 48%;
      padding       : 0 12px;
      color         : #46474a;

      &:first-child {
        width : 100%;
      }

      &:nth-child(6) {
        width : 100%;
      }
    }

    textarea {
      width         : 100%;
      border-radius : 4px;
      box-shadow    : inset 1px 2px 3px rgba(24, 24, 24, 0.05);
      border        : 1px solid rgba(84, 93, 102, 0.5);
      margin-bottom : 18px;
      min-height    : 300px;
      padding       : 7px 12px;
      color         : #46474a;
    }
  }
}

.form {
  &-alert {
    color       : #d9261c;
    font-family : $GothamPro;
    font-size   : 12px;
    font-weight : 400;
  }

  &-button-wrapper {
    .col {
      padding : 0;

      &:last-child {
        text-align : right;
      }
    }

    .catalog__link_blue {
      padding : 10px 20px;
    }
  }
}
