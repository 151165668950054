// ВСПОМОГАТЕЛЬНЫЕ ОБЩИЕ КЛАССЫ
.df {
  display   : flex;
  flex-wrap : wrap;
}

.df--jc-sb {
  @extend .df;
  justify-content : space-between;
}

.df--ai--c {
  @extend .df;
  align-items : center;
}

// УБИРАЕМ ПОДЧЁРКИВАНИЕ ССЫЛОК ПРИ НАВЕДЕНИИ
a:hover {
  text-decoration : none;
}

a:focus {
  outline : none;
}

.padding-offset-left-30px {
  padding : 0 0 0 30px;
}

.embed-responsive {
  height        : 370px;
  width         : 630px;
  margin        : 0 auto 30px;
  border-radius : 11px;
}

// ДЛЯ ФОРМЫ ОБРАТНОЙ СВЯЗИ В МОДАЛКЕ
.modal-content {
  padding  : 30px;
  position : relative;

  .my-close {
    position : absolute;
    right    : 10px;
    top      : 3px;
  }

  .contact__form {
    width   : 100%;
    padding : 0 30px;
  }

  .catalog__page-info-title {
    width          : 100%;
    padding-left   : 0;
    margin-bottom  : 18px;
    text-transform : uppercase;
    font-size      : 18px;
  }

  .form-button-wrapper {
    width   : 100%;
    margin  : 0;
    padding : 0 30px;

    .catalog__link_blue {
      font-size : 12px;
    }

    .form-alert {
      font-size : 11px;
    }
  }
}

.sticky-menu {
  padding-top : 50px;

  .main-menu {
    position : fixed;
    top      : -20px;
    z-index  : 9;
  }
}

.record-list {
  margin  : 0;
  padding : 0;
}

@media (max-width : 1023px) {
  .sticky-menu {
    padding-top : 0;

    .main-menu {
      position : initial;
    }
  }
}

