.clients-page {
  padding-top : 50px;

  .about-us-content__title-description {
    padding-left  : 90px;
    margin-bottom : 54px;

    &:before {
      left : 0;
    }
  }

  &__p {
    color       : #46474a;
    font-family : $GothamPro;
    font-size   : 14px;
    font-weight : 400;
    line-height : 24px;
  }

  &__item {
    padding       : 0 45px;
    margin-bottom : 62px;
  }

  .row-offset {
    margin : 0 -45px;
  }

  .certificate__item {
    margin-bottom : 40px;
  }

  .certificate__title {
    color         : #46474a;
    font-family   : $GothamPro;
    font-size     : 16px;
    font-weight   : 500;
    margin-bottom : 0;

    &:after {
      display : none;
    }
  }

  .certificate__description {
    display : none;
  }
}