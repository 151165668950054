//@import "/bower_components/normalize-css/normalize.css";
@import "/bower_components/flickity/dist/flickity.min.css";
//$grid-gutter-width : 20px;
//$icon-font-path : '/bower_components/bootstrap-sass/assets/fonts/bootstrap/';
//@import "../bower_components/bootstrap-sass/assets/stylesheets/_bootstrap";
//@import "/bower_components/seiyria-bootstrap-slider/dist/css/bootstrap-slider.min.css";
//@import "/bower_components/fancybox/dist/jquery.fancybox.min.css";
//
//$ionicons-font-path : "../bower_components/Ionicons/fonts";
//@import "../bower_components/Ionicons/scss/ionicons";
@import "../bower_components/css-hamburgers/_sass/hamburgers/hamburgers.scss",
"../bower_components/fancybox/dist/jquery.fancybox.css";

@import "https://cdn.jsdelivr.net/npm/social-likes/dist/social-likes_birman.css";

.container {
  padding : 0 10px !important;
}

@import "variables-responsive",
"helper-clases",
"font-faces",
"typography",
"header/header",
"header/header-mobile",
"slider/slider",
"slider/slider-mobile",
"oborydovanie/oborydovanie",
"oborydovanie/oborydovanie-mobile",
"about-company/about-company",
"about-company/about-company-mobile",
"projects-map/projects-map",
"projects-map/projects-map-mobile",
"our-clients/our-clients",
"our-clients/our-clients-mobile",
"about-us-page/about-us-content",
"about-us-page/about-us-content-mobile",
"services/services-page",
"services/services-page-mobile",
"catalog/catalog",
"catalog/catalog-mobile",
"catalog-page/catalog-page",
"catalog-page/catalog-page-mobile",
"project/projects",
"project/project-page-mobile",
"project-item/project-item",
"contacts/contacts-page",
"contacts/contacts-page-mobile",
"clients/clients-page",
"clients/clients-page-mobile",
"footer/footer",
"footer/footer-mobile";