@media screen and (max-width : 1023px) {
  .oborydovanie {
    margin-bottom : 80px;

    &__description {
      font-size : 14px;
    }

    .flickity-prev-next-button {
      top     : 109%;
      border  : 1px solid rgba(0, 2, 25, 1);
      z-index : 1;
    }

    .carousel {
      .flickity-page-dots {
        bottom      : -43px;
        margin-left : -6px;
      }

      .flickity-page-dots .dot {
        background : #46535f;
        opacity    : .5;
      }

      .flickity-page-dots .dot.is-selected {
        background : #00458f;
        opacity    : 1;
      }

      &-cell {
        width        : 246px;
        margin-right : 12px;
      }

      &:after {
        content : 'flickity';
        display : none;
      }
    }

    &__item {
      width : 246px;
    }

    &__icon {
      width  : 35px;
      height : 35px;

      img {
        max-width : 60%;
      }
    }
  }
}

@media screen and (min-width : 1023px) {
  .oborydovanie {
    /* disable Flickity for large devices */
    .carousel:after {
      content : '';
    }
  }
}