.header {
  position : relative;

  .form-control {
    cursor : pointer;

    &:hover {
      border : solid 1px #00458f;
    }
  }

  .logo {
    margin-top : 10px;
  }

  &:after {
    content          : '';
    height           : 140px;
    position         : absolute;
    background-color : #f0f0f0;
    background-image : linear-gradient(51deg, #d0d0d0 0%, #f0f0f0 24%, #f9f9f9 44%, #e2e2e2 100%);
    right            : 0;
    bottom           : -8px;
    left             : 0;
    z-index          : -1;
  }
}

// ДЛЯ ГЛАВНОГО МЕНЮ
.main-menu {
  padding       : 0;
  margin        : 0;
  list-style    : none;
  //background-color : #00458f;
  //background       : linear-gradient(to top, #00458f 0%, #0054af 100%);
  border-radius : 5px;
  transform     : translateY(18px);
  overflow      : hidden;

  li {
    display          : block;
    background-color : #00458f;
    background       : linear-gradient(to top, #00458f 0%, #0054af 100%);
    position         : relative;

    &:first-child {
      border-radius : 5px 0 0 5px;
    }

    &:last-child {
      border-radius : 0 5px 5px 0;
    }

    &:after {
      content          : '';
      display          : block;
      height           : 100%;
      width            : 100%;
      background-color : #d9261c;
      background       : linear-gradient(to top, #d9261c 0%, #ed0d00 100%);
      position         : absolute;
      top              : 0;
      opacity          : 0;
      transition       : all .5s;
    }

    &:hover {
      &:after {
        opacity : 1;
      }
    }
  }

  a {
    color          : #ffffff;
    font-family    : $GothamPro;
    font-size      : 18px;
    font-weight    : 500;
    text-transform : uppercase;
    letter-spacing : -0.09px;
    display        : block;
    padding        : 14px 28.9px;
    border-right   : solid 1px rgba(186, 186, 186, .25);
    position       : relative;
    z-index        : 1;

    &:hover {
      text-decoration : none;
    }
  }

  li.active {
    background-color : #d9261c;
    background       : linear-gradient(to top, #d9261c 0%, #ed0d00 100%);
  }
}

// ОБЁРТКА ВЫБОРА ЯЗЫКА И СЛОГАНА
.description-select-lang-wrapper {
  padding     : 58px 0 0 27px;
  align-items : center;
}

// ДЛЯ МЕНЮ КОНТАКТОВ
.contact-menu {
  padding    : 0;
  margin     : 31px 0 0;
  list-style : none;
  position   : relative;

  &__lang-menu-wrapper {
    width : 70%;
  }

  li {
    color       : #5f5e5e;
    font-family : $GothamPro;
    font-size   : 14px;
    font-weight : 500;
    padding     : 0 27px;

    &:nth-child(2) {
      border-left : solid 1px rgba(186, 186, 186, .25);

      a {
        position : relative;

        &:after {
          content          : '';
          background-color : #d9261c;
          opacity          : .4;
          height           : 1px;
          position         : absolute;
          right            : 0;
          bottom           : 2px;
          width            : 81%;
          transition       : all .3s;
        }

        &:hover {
          &:after {
            opacity : 0;
          }
        }
      }
    }

    &:nth-child(3) {
      position : absolute;
      right    : 0;
      padding  : 0;
    }
  }

  &__icon {
    width        : 20px;
    margin-right : 10px;
  }

  a {
    color       : #00458f;
    font-family : $GothamPro;
    font-size   : 16px;
    font-weight : 500;
    line-height : 20px;
  }
}

// ДЛЯ ОПИСАНИЯ В HEADER
.description {
  color       : #00458f;
  font-family : $GothamPro;
  font-size   : 15px;
  font-weight : 700;
  font-style  : italic;
  width       : 660px;

  img {
    max-width : 100%;
  }
}

// ДЛЯ ВЫБОРА ЯЗЫКА
.select-lang {
  .dropdown-toggle {
    background     : transparent;
    border-radius  : 3px;
    border         : 1px solid #ffffff;
    color          : #00458f;
    font-family    : $GothamPro;
    font-size      : 15px;
    font-weight    : 500;
    text-transform : uppercase;
    letter-spacing : 0.75px;
    transition     : all .3s;

    &:hover {
      border : solid 1px #00458f;

      &:after {
        background : url('../img/icon/arrow-bottom-blue.svg'), no-repeat;
      }
    }

    &:after {
      border              : 0;
      content             : '';
      background          : url('../img/icon/arrow-bottom.svg'), no-repeat;
      height              : 9px;
      width               : 15px;
      background-position : center;
      background-size     : cover;
      vertical-align      : baseline;
      transition          : all .3s;
    }

    &:focus {
      outline : none !important;
    }
  }

  .dropdown-menu {
    min-width : 5.3rem;
  }

  .dropdown-item {
    padding : .25rem .8rem;

    &:hover, &:focus {
      background : transparent;
    }
  }
}

.mobile-submenu {
  display : none;
}


