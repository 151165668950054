@media (max-width : 1023px) {
  .contacts-page {
    padding : 10px 0 0 0;

    .fon_white {
      margin-bottom : 10px;
    }

    .form-button-wrapper {
      .col:first-child {
        margin : 0;
      }
    }

    .main-h2 {
      margin-bottom : 10px;
    }

    .border-right {
      padding : 15px;
    }

    .border-left {
      padding : 10px 15px;
    }
  }

  .contact__form {
    width : 100%;

    input {
      width : 100%;
    }
  }

  .contact__adres {
    padding : 0 15px;

    &-link {
      padding : 0;
    }
  }
}