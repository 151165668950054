@media (max-width : 1023px) {
  .catalog-page {
    margin-top : 20px;

    .main-h2 {
      margin-bottom : 20px;
    }

    .about-us-content__title-description {
      margin-bottom : 20px;
      padding       : 0;
    }

    .record-list {
      padding : 0;
    }

    &__item-img {
      position : initial;
      height   : 260px;
    }

    &__item-logo {
      width : 120px;
    }

    &__item-title {
      font-size : 18px;
    }

    &__item-icon {
      top    : 20px;
      left   : 20px;
      height : 50px;
      width  : 50px;
    }
  }
}