@media (max-width : 1023px) {
  .our-clients {
    margin-bottom : 40px;

    .carousel-cell {
      width : 43%;
    }

    .client-item {
      width : 145px;
    }

    .carousel .flickity-page-dots {
      bottom : -20px;
    }

    .carousel .flickity-page-dots .dot {
      background : #46535f;
      opacity    : .5;
    }

    .carousel .flickity-page-dots .dot.is-selected {
      background : #00458f;
      opacity    : 1;
    }

    .flickity-prev-next-button {
      top     : 103%;
      z-index : 1;
    }
  }
}