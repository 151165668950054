@media (max-width : 1023px) {
  .about-us-content {
    padding-top : 20px;

    #pills-home {
      p {
        font-size : 12px;
      }

      ul {
        padding-left : 15px;
      }
    }

    .nav {
      margin-bottom : 30px;

      .nav-item {
        margin-right : 7px;
      }
    }

    &__title-description {
      font-size     : 20px;
      margin-bottom : 20px;
      padding-left  : 0;

      p {
        font-size : 20px !important;
      }

      &:before {
        background-size : contain !important;
        height          : 26px;
        width           : 30px;
        position        : initial;
        display         : block;
        float           : left;
        margin-right    : 20px;
      }
    }
  }

  .nav-link {
    font-size : 13px;
    padding   : .5rem .4rem;
  }

  .col:first-child {
    //margin-bottom : 30px;
    padding-left : 15px !important;
  }

  .certificates {
    justify-content : space-between;
  }

  .certificate {
    &__item {
      width  : 45%;
      margin : 0 5px 40px 5px;
    }

    &__title-img-wrapper {
      width : auto;
    }

    &__title {
      font-size  : 14px;
      min-height : 40px;
    }

    &__description {
      min-height : 62px;
      height     : auto;

      p {
        font-size : 12px;
      }
    }

    &__img {
      min-height : 200px;

      a {
        width : 100%;
      }
    }
  }

  .main-h1_white-wrapper {
    padding-left    : 20px;
    height          : 120px;
    background-size : cover !important;
  }
}