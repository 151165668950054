.sticky-top {
  top : 60px !important;
}

.catalog {
  padding-top : 50px;

  .links-wrapper {
    margin-bottom : 30px;
    padding       : 0 14px;
  }

  .little {
    padding : 0 14px;
  }

  .catalog__link-mail {
    position : relative;

    &:after {
      content          : '';
      background-color : #d9261c;
      opacity          : .8;
      height           : 1px;
      position         : absolute;
      right            : 0;
      bottom           : 7px;
      width            : 81%;
      transition       : all .3s;
    }

    &:hover {
      &:after {
        opacity : 0;
      }
    }
  }

  &__page-info {
    padding-bottom : 38px;

    &-title {
      color        : #00458f;
      font-family  : $GothamPro;
      font-size    : 22px;
      font-weight  : 500;
      line-height  : 26px;
      position     : relative;
      width        : 50%;
      padding-left : 100px;

      span {
        text-transform : uppercase;
      }

      &-img {
        position         : absolute;
        height           : 80px;
        width            : 80px;
        box-shadow       : 0 2px 2px rgba(0, 0, 0, 0.1);
        border-radius    : 5px;
        background-color : #0054af;
        background-image : linear-gradient(to top, #00458f 0%, #0054af 100%);
        display          : flex;
        justify-content  : center;
        align-items      : center;
        top              : 0;
        left             : 0;
      }
    }

    &-description {
      color        : #46474a;
      font-family  : $GothamPro;
      font-size    : 14px;
      font-weight  : 400;
      line-height  : 24px;
      width        : 50%;
      padding-left : 50px;
    }
  }

  .fon_gray {
    padding : 50px 0;
  }

  .shadow {
    box-shadow    : 0 3px 4px rgba(0, 0, 0, 0.1);
    border-radius : 3px;
  }

  &__title {
    box-shadow       : 0 2px 2px rgba(0, 0, 0, 0.1);
    border-radius    : 3px 3px 0 0;
    background-color : #6b737a;
    background-image : linear-gradient(to top, #414e5b 0%, #6b737a 100%);
    color            : #ffffff;
    font-family      : $GothamPro;
    font-size        : 22px;
    font-weight      : 500;
    text-transform   : uppercase;
    padding          : .75rem 1.25rem;

    span {
      position : relative;

      &:before {
        content          : '';
        position         : absolute;
        width            : 3px;
        top              : 3px;
        bottom           : 3px;
        left             : -20px;
        background-color : #d9261c;
      }
    }
  }

  .list-group-item {
    border        : none;
    box-shadow    : 0 2px 2px rgba(0, 0, 0, 0.1);
    border-bottom : solid 2px rgba(225, 225, 225, .1);
    color         : #00458f;
    font-family   : $GothamPro;
    font-size     : 16px;
    font-weight   : 500;
    line-height   : 26px;
    transition    : all .3s;
    margin-top    : 3px;

    &:first-child {
      margin        : 0;
      border-radius : 0;
    }
  }

  .list-group-item.active {
    box-shadow       : 0 2px 2px rgba(0, 0, 0, 0.1);
    background-color : #000000;
    background-image : linear-gradient(to top, #00458f 0%, #0054af 100%);
    color            : white;
    margin-top       : -3px;
  }

  &__list-item {
    border-bottom : solid 2px rgba(0, 0, 0, .1);
    padding       : 24px 14px;

    .catalog__link {
      &_blue, &_red {
        margin-top : 30px;

        span {
          &:after {
            bottom : 0;
          }
        }
      }
    }

    &:last-child {
      border-bottom : none;
    }
  }

  &__link {
    box-shadow     : 0 2px 2px rgba(0, 0, 0, 0.1);
    border-radius  : 3px;
    color          : #ffffff;
    font-family    : $GothamPro;
    font-size      : 12px;
    font-weight    : 500;
    text-transform : uppercase;
    padding        : 7px 16px;
    display        : inline-block;
    margin-right   : 20px;

    &:last-child {
      margin : 0;
    }

    span {
      position : relative;

      &:after {
        content    : '';
        position   : absolute;
        height     : 1px;
        left       : 0;
        width      : 0;
        background : white;
        transition : all .3s;
      }
    }

    &:hover {
      color : #ffffff;

      span {
        &:after {
          width : 100%;
        }
      }
    }

    &_blue {
      @extend .catalog__link;
      background-color : #0054af;
      background-image : linear-gradient(to top, #00458f 0%, #0054af 100%);
    }

    &_red {
      @extend .catalog__link;
      background-color : #ed0d00;
      background-image : linear-gradient(to top, #d9261c 0%, #ed0d00 100%);
    }

    &-mail {
      color       : #00458f;
      font-family : $GothamPro;
      font-size   : 16px;
      font-weight : 500;
      line-height : 20px;
      position    : relative;
    }

    &_gray {
      @extend .catalog__link;
      background-color : #a7abaf;
      background-image : linear-gradient(to top, #656d75 0%, #a7abaf 100%);
    }
  }

  &__system-img {
    text-align : center;
    padding    : 30px 0 20px 0;
    position   : relative;

    .icon-fancy {
      position : absolute;
      right    : 50px;
      bottom   : 30px;
    }
  }

  &__characteristics {
    background-color : #0050a7;
    min-height       : 75px;

    .catalog__link_gray {
      span {
        &:after {
          bottom : 0;
        }
      }
    }
  }

  &__characteristics-title {
    color          : #fefefe;
    font-family    : $GothamPro;
    font-size      : 16px;
    font-weight    : 500;
    line-height    : 20px;
    text-transform : uppercase;
  }

  &__characteristics-wrapper {
    position   : relative;
    z-index    : 1;
    margin-top : 30px;

    &:before {
      content          : '';
      position         : absolute;
      background-color : #0050a7;
      top              : 0;
      right            : 0;
      left             : -3.6%;
      bottom           : 0;
      z-index          : -1;
    }

    &:after {
      content          : '';
      position         : absolute;
      background-color : #0050a7;
      top              : 0;
      right            : -3.6%;
      left             : 0;
      bottom           : 0;
      z-index          : -1;
    }

    // ДЛЯ СПИСКА
    .card-body {
      ul {
        margin     : 0;
        padding    : 0;
        list-style : none;
        display    : flex;
        flex-wrap  : wrap;

        li {
          position    : relative;
          width       : 220px;
          margin      : 19px 20px;
          color       : #ffffff;
          font-family : $GothamProMedium;
          font-size   : 14px;
          font-weight : 500;
          line-height : 20px;

          strong {
            display : block;
          }

          p {
            color       : #ffffff;
            font-family : $GothamProMedium;
            font-size   : 14px;
            font-weight : 500;
            line-height : 20px;

            strong {
              display : block;
            }
          }

          &:before {
            content          : '';
            position         : absolute;
            width            : 6px;
            height           : 6px;
            background-color : #ff0000;
            top              : 6px;
            left             : -12px;
          }

          &:after {
            content    : '';
            position   : absolute;
            height     : 1px;
            background : rgba(225, 225, 225, 0.4);
            width      : 100%;
            left       : -12px;
            bottom     : -12px;
          }
        }
      }
    }
  }

  .card-body {
    background-color : #0050a7;
    border-radius    : 0;
    border           : none;
    padding          : 0 0 30px 0;
    position         : relative;
  }

  p {
    color       : #46474a;
    font-family : $GothamPro;
    font-size   : 14px;
    font-weight : 400;
    line-height : 20px;
  }

  .fixed {
    position : fixed;
    top      : 5px;
    width    : 260px;
  }
}

.little {
  color       : #46474a;
  font-family : $GothamPro;
  font-size   : 12px;
  font-weight : 400;
  margin-top  : 15px;
  padding-top : 15px;
  border-top  : solid 2px rgba(0, 0, 0, .1);
}

.links-wrapper {
  width    : 460px;
  position : relative;

  .catalog__link_blue {
    display  : block;
    position : relative;

    &:before {
      content          : '';
      width            : 1px;
      height           : 16px;
      position         : absolute;
      background-color : #bababa;
      opacity          : 0.5;
      top              : 9px;
      right            : -28px;
    }

    span {
      &:after {
        bottom : 0;
      }
    }
  }
}

.header-page {
  height        : 220px;
  position      : relative;
  padding-left  : 40px;
  margin-bottom : 38px;

  &__logo {
    padding          : 0 28px;
    box-shadow       : 0 2px 2px rgba(0, 0, 0, 0.1);
    border-radius    : 5px;
    background-color : #ffffff;
    line-height      : 115px;
    margin-right     : 30px;
    width            : 235px;

    img {
      max-width : 100%;
    }
  }

  &__title {
    color          : #ffffff;
    font-family    : $GothamPro;
    font-size      : 26px;
    font-weight    : 500;
    text-transform : uppercase;
    width          : 413px;

    span {
      font-size : 12px;
    }
  }

  &__link {
    box-shadow       : 0 2px 2px rgba(0, 0, 0, 0.1);
    border-radius    : 3px;
    background-color : #a7abaf;
    background-image : linear-gradient(to top, #656d75 0%, #a7abaf 100%);
    color            : #ffffff;
    font-family      : $GothamPro;
    font-size        : 16px;
    font-weight      : 500;
    text-transform   : uppercase;
    position         : absolute;
    padding          : 10px 15px 10px 50px;
    right            : 30px;

    &:before {
      content    : '';
      background : url("/build/img/icon/left-arrow.svg");
      position   : absolute;
      height     : 15px;
      width      : 22px;
      left       : 15px;
      top        : 13px;
    }

    &:hover {
      color : white;
    }
  }
}

.fon {
  &_gray {
    background-color : #f5f5f5;
  }

  &_white {
    background-color : #ffffff;
  }
}

.fon_gray {
  .fon_white {
    padding : 0;
  }
}