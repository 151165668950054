.main-slider {
  padding-top   : 50px !important;
  margin-bottom : 50px;

  // ДЛЯ СЛАЙДЕРА
  .flickity-slider {
    transform : none !important;
  }

  .carousel-cell {
    top        : 0;
    right      : 0;
    bottom     : 0;
    left       : 0 !important;
    opacity    : 0;
    transition : opacity 2.6s ease-in-out;
    z-index    : -1;
    height     : 450px;
    overflow   : hidden;
  }

  .carousel-cell.is-selected {
    opacity : 1;
    z-index : 0
  }

}

// ДЛЯ ЭЛЕМЕНТОВ УПРАВЛЕНИЯ СЛАЙДЕРА
.carousel {
  .flickity-prev-next-button {
    width         : 35px;
    height        : 35px;
    border-radius : 3px;
    border        : 1px solid rgba(0, 2, 25, .25);
    background    : transparent;
  }

  .flickity-prev-next-button.previous {
    left : -52px;
  }

  .flickity-prev-next-button.next {
    right : -52px;
  }

  .flickity-page-dots {
    bottom      : 30px;
    margin-left : 40px;
    text-align  : left;

    .dot {
      width      : 12px;
      height     : 12px;
      background : white;
    }
  }
}

// ДЛЯ БАННЕРА
.banner {
  position : relative;
  height   : 100%;

  .image {
    background-size : cover !important;
    position        : absolute;
    right           : 0;
    top             : 0;
    bottom          : 0;
    width           : 740px;

    img{
      max-width: 100%;
    }
  }

  &__info-block {
    width   : 374px;
    padding : 40px 40px 0 40px
  }

  &__title {
    color          : #ffffff;
    font-family    : $GothamPro;
    font-size      : 35px;
    font-weight    : 500;
    text-transform : uppercase;
    margin-bottom  : 30px;
    position       : relative;

    &:after {
      content          : '';
      height           : 3px;
      width            : 55px;
      background-color : #fff;
      opacity          : .5;
      position         : absolute;
      left             : 0;
      bottom           : -17px;
    }
  }

  &__description {
    color         : #ffffff;
    font-family   : $GothamPro;
    font-size     : 16px;
    font-weight   : 500;
    margin-bottom : 28px;
  }

  &__link {
    color            : #ffffff;
    font-family      : $GothamPro;
    font-size        : 16px;
    font-weight      : 500;
    text-transform   : uppercase;
    display          : inline-block;
    background-color : #d9261c;
    background       : linear-gradient(to top, #d9261c 0%, #ed0d00 100%);
    padding          : 10px 22px;
    border-radius    : 3px;

    span {
      position : relative;

      &:after {
        content    : '';
        position   : absolute;
        background : #fff;
        height     : 2px;
        bottom     : -1px;
        left       : 0;
        width      : 0;
        transition : all .2s ease-in-out;
      }
    }

    &:hover {
      color : #ffffff;

      span {
        &:after {
          content    : '';
          position   : absolute;
          background : #fff;
          height     : 2px;
          bottom     : -1px;
          width      : 100%;
          transition : all .2s ease-in-out;
        }
      }
    }
  }
}

