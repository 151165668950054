.footer {
  background    : url("../img/footer-fon.jpg");
  height        : 172px;
  border-bottom : solid 4px #f60e0e;
  position      : relative;

  .container {
    padding  : 40px 5px 0 !important;
    position : relative;
    height   : 100%;
  }

  &__logo {
    margin-bottom : 26px;
  }

  // ДЛЯ МЕНЮ КОНТАКТОВ
  .contact-menu {
    margin : 0;

    li {
      padding : 0 0 20px 0;

      &:nth-child(2) {
        border : none;

        a {
          &:after {
            content          : '';
            background-color : #d9261c;
            opacity          : 0.8;
            height           : 1px;
            position         : absolute;
            right            : 0;
            bottom           : 1px;
            width            : 81%;
            transition       : all .3s;
          }

          &:hover {
            &:after {
              opacity : 0;
            }
          }
        }
      }

      &:last-child {
        padding : 0;
      }
    }

    a {
      color : #ffffff;
    }
  }
}

//  ДЛЯ СПИСКА СТАНДАРТОВ КАЧЕСТВА
.iso-menu {
  margin     : 0 0 0 50px;
  padding    : 0;
  list-style : none;
  position   : relative;

  &:after {
    content          : '';
    position         : absolute;
    height           : 79px;
    background-color : #ffffff;
    opacity          : 0.25;
    width            : 1px;
    top              : -10px;
    left             : -25px;
  }

  li {
    color          : #ffffff;
    font-family    : $GothamPro;
    font-size      : 14px;
    font-weight    : 100;
    letter-spacing : 0.35px;
    padding        : 0 0 20px 0;

    &:last-child {
      padding : 0;
    }
  }

  i {
    display      : inline-block;
    width        : 20px;
    margin-right : 10px;
    opacity      : .5;

    img {
      max-width : 100%;
    }
  }
}

.logo-and-designer {
  text-align : right;
  position   : absolute;
  top        : 27px;
  right      : 0;
}

.copyright {
  opacity        : 0.8;
  color          : #ffffff;
  font-family    : $GothamPro;
  font-size      : 12px;
  font-weight    : 400;
  text-transform : uppercase;
  letter-spacing : 0.6px;
  position       : absolute;
  bottom         : 12px;
  left           : 0;
}

.designer {
  color       : #ffffff;
  font-family : $GothamPro;
  font-size   : 14px;
  font-weight : 400;
  opacity     : 0.8;

  a {
    @extend .designer;
    text-decoration : underline;
  }
}

// ПРИЖИМАЕМ FOOTER К НИЗУ
.body {
  display        : flex;
  min-height     : 100vh;
  flex-direction : column;
  overflow-x     : hidden;
  position       : relative;
}

.footer-down {
  flex : 1;
}