@media (max-width : 1023px) {
  .service-page {
    padding-top : 20px;

    .main-h1_blue {
      margin-bottom : 20px;
    }

    .about-us-content__title-description {
      margin  : 0 0 20px 0;
      padding : 0;
    }

    .record-list {
      padding : 0;
    }

    .service {
      &__item {
        height : 300px;
      }

      &__img {
        width         : 60px;
        height        : 60px;
        padding       : 0;
        position      : absolute;
        top           : 20px;
        left          : 20px;
        z-index       : 1;
        border-radius : 5px;
        overflow      : hidden;
        text-align    : center;
        line-height   : 60px;

        img {
          max-width : 50%;
        }
      }

      &__title {
        padding             : 100px 0 0 20px;
        font-size           : 18px;
        background-size     : cover !important;
        background-position : center !important;
        overflow            : hidden;

        span {
          width : auto;
        }
      }

      &__link {
        right  : auto;
        bottom : 30px;
        left   : 20px;
      }

      &__item-collapse-content-wrapper {
        .card {
          padding : 30px 0 0 0;
        }
      }

      &__collapse-content {
        .little {
          margin-top : 10px;
        }

        &-ul {
          padding-bottom : 30px;

          li {
            padding-left : 40px;

            &:before {
              left : 0;
            }
          }

          &:after {
            right : 0;
            left  : 0;
          }
        }
      }
    }

    .links-wrapper {
      width : auto;

      .catalog__link_blue {
        margin : 0 0 20px 0;

        &:before {
          display : none;
        }
      }

      .catalog__link-mail:after {
        bottom : 0;
        width  : 88%;
      }
    }
  }
}