.about-slider {
  position      : relative;
  height        : 447px;
  margin-bottom : 50px;

  // ДЛЯ СЛАЙДЕРА
  .flickity-slider {
    transform : none !important;
  }

  .carousel-cell {
    top        : 0;
    right      : 0;
    bottom     : 0;
    left       : 0 !important;
    opacity    : 0;
    transition : opacity 2.6s ease-in-out;
    z-index    : -1;
    height     : 447px;
    overflow   : hidden;
  }

  .carousel-cell.is-selected {
    opacity : 1;
    z-index : 0
  }

  //  ДЛЯ БЛОКА С ИНФОРМАЦИЕЙ
  .about {

    &__info-block {
      position : absolute;
      top      : 0;
      right    : 0;
      bottom   : 0;
      left     : 0;
      z-index  : 1;

      .container {
        position : relative;
        height   : 100%;
      }
    }

    &__title-quantity-wrapper {
      position      : relative;
      height        : 120px;
      margin-bottom : 20px;

      &:after {
        content          : '';
        position         : absolute;
        height           : 4px;
        background-color : #ffffff;
        opacity          : 0.5;
        width            : 56px;
        bottom           : 0;
      }
    }

    &__title {
      padding        : 50px 0 46px 0;
      color          : #ffffff;
      font-family    : $GothamPro;
      font-size      : 35px;
      font-weight    : 500;
      text-transform : uppercase;
      line-height    : 30px;
    }

    &__statistics-item {
      width : 243px;
    }

    &__statistics-item-quantity {
      color         : #ffcb05;
      font-family   : $GothamPro;
      font-size     : 80px;
      font-weight   : 300;
      line-height   : 58px;
      margin-bottom : 17px;
      text-shadow   : red 0 0 2px;
    }

    &__statistics-item-title {
      color          : #ffffff;
      font-family    : $GothamPro;
      font-size      : 18px;
      font-weight    : 400;
      text-transform : uppercase;
      line-height    : 13px;
    }

    &__statistics-item-description {
      color       : #ffffff;
      font-family : $GothamPro;
      font-size   : 18px;
      font-weight : 500;
    }

    &__podrobnee-link {
      @extend .banner__link;
      background-color : #0054af;
      padding          : 7px 22px;
      background-image : linear-gradient(to top, #00458f 0%, #0054af 100%);
      position         : absolute;
      bottom           : 40px;
      left             : 0;

      span {
        position : relative;

        &:after {
          content    : '';
          position   : absolute;
          background : #fff;
          height     : 2px;
          bottom     : -1px;
          left       : 0;
          width      : 0;
          transition : all .2s ease-in-out;
        }
      }

      &:hover {
        span {
          width : 100%;
        }
      }
    }
  }

}