@media (max-width : 1023px) {
  .projects-map {
    &__points-mobile-wrapper {
      overflow-x : auto;
    }

    &__points {
      top                 : 0;
      background-size     : cover;
      background-position : center;
      width               : 950px;
      background-repeat   : no-repeat;
    }

    &__title-project {
      font-size     : 20px;
      margin-bottom : 15px;

      &:after {
        bottom : -7px;
      }
    }
  }

  .projects-map_bottom-gray {
    height        : 520px;
    margin-bottom : 35px;

    &:before {
      content          : '';
      background-color : rgba(193, 196, 200, .3);
      height           : 110px;
      position         : absolute;
      top              : -110px;
      right            : 0;
      left             : 0;
    }
  }
}