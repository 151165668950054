.service-page {
  padding-top : 50px;

  .main-h1_blue {
    margin-bottom : 50px;
  }

  .about-us-content__title-description {
    padding-right : 30px;
    padding-left  : 95px;

    &:before {
      left : 0;
    }
  }

  &__description {
    margin-bottom : 40px;
  }

  .service {
    &__item {
      height        : 135px;
      display       : flex;
      border-radius : 5px;
      overflow      : hidden;
      position      : relative;
      box-shadow    : 0 1px 10px #dadadb;

      &:hover {
        .service__title {
          filter        : grayscale(0);
          border-radius : 0 3px 3px 0;
        }
      }
    }

    &__item-collapse-content-wrapper {
      margin-bottom : 50px;

      .card {
        border  : none;
        padding : 38px 0 38px 140px;
      }
    }

    &__collapse-content {
      .little {
        margin-top : 47px;
        border-top : none;
      }
    }

    &__collapse-content-description {
      font-family : $GothamProMedium;
      font-size   : 18px;
      font-weight : 500;
      line-height : 26px;
      color       : #46474a;

      &_little {
        margin-top    : 26px;
        margin-bottom : 26px;
        font-size     : 16px;
      }
    }

    &__collapse-content-ul {
      padding        : 0;
      margin         : 0;
      list-style     : none;
      padding-bottom : 47px;
      position       : relative;

      &:after {
        content          : '';
        position         : absolute;
        background-color : #dadadb;
        height           : 2px;
        width            : 100%;
        bottom           : -5px;
        left             : -37px;
      }

      li {
        color         : #00458f;
        font-family   : $GothamProMedium;
        font-weight   : 500;
        line-height   : 20px;
        font-size     : 18px;
        margin-bottom : 20px;
        position      : relative;

        &:last-child {
          margin : 0;
        }

        &:before {
          content             : '';
          background          : url("/build/img/icon/ico-check-red.svg");
          position            : absolute;
          width               : 18px;
          height              : 18px;
          background-position : center;
          background-size     : cover;
          left                : -37px;
        }
      }
    }

    &__img {
      width    : 114px;
      height   : 100%;
      padding  : 24px;
      position : relative;

      &:before {
        content          : '';
        background-color : #002954;
        background-image : linear-gradient(to top, #00458f 0%, #0054af 100%);
        position         : absolute;
        top              : 0;
        right            : 0;
        bottom           : 0;
        left             : 0;
        z-index          : -1;
        opacity          : 1;
        transition       : all .3s;
      }

      &:after {
        content          : '';
        background-color : #d9261c;
        background       : linear-gradient(to top, #d9261c 0, #ed0d00 100%);
        position         : absolute;
        top              : 0;
        right            : 0;
        bottom           : 0;
        left             : 0;
        z-index          : -2;
        opacity          : 0;
        transition       : all .3s;
      }

      img {
        width : 100%;
      }
    }

    &__title {
      position        : relative;
      width           : 100%;
      color           : #ffffff;
      font-family     : $GothamPro;
      font-size       : 26px;
      font-weight     : 500;
      text-transform  : uppercase;
      padding         : 30px 0 0 28px;
      filter          : grayscale(0.6);
      transition      : all .3s;
      border-radius   : 0 3px 3px 0;
      background-size : cover !important;

      span {
        position : relative;
        z-index  : 1;
        width    : 780px;
        display  : block;
      }

      &:before {
        content       : '';
        top           : 0;
        right         : 0;
        bottom        : 0;
        left          : 0;
        position      : absolute;
        background    : rgba(7, 59, 117, .3);
        border-radius : 0 3px 3px 0;
      }
    }

    &__link {
      position       : absolute;
      right          : 40px;
      bottom         : 36px;
      color          : #ffffff;
      font-family    : $GothamPro;
      font-size      : 16px;
      font-weight    : 500;
      text-transform : uppercase;
      box-shadow     : 0 2px 2px rgba(0, 0, 0, 0.1);
      border-radius  : 3px;
      padding        : 8px 22px;
      cursor         : pointer;

      &:after {
        content          : '';
        position         : absolute;
        top              : 0;
        right            : 0;
        left             : 0;
        bottom           : 0;
        background-color : #0054af;
        background-image : linear-gradient(to top, #00458f 0%, #0054af 100%);
        border-radius    : 3px;
        z-index          : 0;
        opacity          : 1;
        transition       : all .3s;
      }

      &:before {
        content          : '';
        position         : absolute;
        top              : 0;
        right            : 0;
        left             : 0;
        bottom           : 0;
        background-color : #a7abaf;
        background-image : linear-gradient(to top, #656d75 0, #a7abaf 100%);
        border-radius    : 3px;
        z-index          : 0;
        opacity          : 0;
        transition       : all .3s;
      }

      span {
        position : relative;
        z-index  : 1;

        &:after {
          content    : '';
          position   : absolute;
          background : #fff;
          height     : 2px;
          bottom     : -1px;
          width      : 0;
          transition : all .2s ease-in-out;
          left       : 0;
        }
      }

      &:hover {
        span {
          &:after {
            width : 100%;
          }
        }
      }
    }
  }

  // ФОН ИКОНОК И КНОПОК В ОТКРЫТОМ СОСТОЯНИИ
  .service__item_active-fon {
    .service__img {
      &:before {
        opacity : 0;
      }

      &:after {
        opacity : 1;
      }
    }

    .service__link {
      &:after {
        opacity : 0;
      }

      &:before {
        opacity : 1;
      }
    }
  }

  .links-wrapper {
    width : 430px;

    .catalog__link-mail {
      &:after {
        content          : '';
        background-color : #d9261c;
        opacity          : .8;
        height           : 1px;
        position         : absolute;
        right            : 0;
        bottom           : 8px;
        width            : 81%;
        transition       : all .3s;
      }

      &:hover {
        &:after {
          opacity : 0;
        }
      }
    }
  }
}