.fon_gray {
  .oborydovanie__link {
    margin : 0;

    &:before {
      background-color : #0054af;
      background-image : linear-gradient(to top, #00458f 0%, #0054af 100%);
      opacity          : 0;
    }
  }
}

.projects-page {
  padding-top : 50px;
}

.project__item {
  border-radius : 3px;
  overflow      : hidden;
  margin-bottom : 40px;
  box-shadow    : 0 1px 10px #dadadb;
  color         : #46474a;
  transition    : all .3s;
  position      : relative;
  min-height    : 240px;

  .df--jc-sb {
    position : absolute;
    right    : 15px;
    left     : 15px;
    bottom   : 15px;
    z-index  : 5;
  }

  &:before {
    content       : '';
    opacity       : 1;
    border        : solid 1px transparent;
    border-radius : 3px;
    position      : absolute;
    top           : 0;
    right         : 0;
    bottom        : 0;
    left          : 0;
    transition    : all .3s;
  }

  &:hover {
    .project__item-title {
      a {
        color : #00458f;
      }
    }

    &:before {
      border : solid 1px #00458f;
    }

    .oborydovanie__link {
      &:before {
        background-color : #0054af;
        background-image : linear-gradient(to top, #00458f 0%, #0054af 100%);
        opacity          : 1;
      }
    }
  }

  &-img {
    position : relative;
    z-index  : 4;

    img {
      width : 100%;
    }
  }

  &-info-fon {
    padding  : 17px 19px;
    position : relative;
    z-index  : 4;
  }

  &-title {
    font-family   : $GothamProMedium;
    font-size     : 18px;
    font-weight   : 500;
    margin-bottom : 20px;

    a {
      color : #46474a;
    }
  }

  &-description {
    font-family   : $GothamPro;
    font-size     : 14px;
    font-weight   : 400;
    line-height   : 17px;
    margin-bottom : 40px;
  }

  .date {
    opacity     : 0.5;
    color       : #46474a;
    font-family : $GothamPro;
    font-size   : 12px;
    font-weight : 400;
  }
}

.projects-content-top-offset {
  padding-top : 30px;
}