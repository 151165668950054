// ДЛЯ БЛОКА ОБОРУДОВАНИЯ
.oborydovanie {
  margin-bottom : 50px;

  &__item {
    width            : 273px;
    min-height       : 382px;
    padding-bottom   : 10px;
    border-radius    : 3px;
    overflow         : hidden;
    box-shadow       : 0 3px 4px rgba(0, 0, 0, 0.1);
    background-color : #6b737a;
    background-image : linear-gradient(to top, #414e5b 0%, #6b737a 100%);
    position         : relative;

    &-wrapper {
      position : relative;
      z-index  : 1;
    }

    &:before {
      content          : '';
      display          : block;
      height           : 100%;
      width            : 100%;
      background-color : #0054af;
      background-image : linear-gradient(to top, #00458f 0%, #0054af 100%);
      position         : absolute;
      top              : 0;
      opacity          : 0;
      transition       : all .5s;
    }

    &:hover {
      &:before {
        opacity : 1;
      }

      .oborydovanie__img {
        transform : scale(0.98);
        filter    : grayscale(0);
      }

      .oborydovanie__link:before {
        opacity : 1;
      }

      .oborydovanie__icon:after {
        opacity : 1;
      }
    }
  }

  &__icon {
    position         : absolute;
    top              : 20px;
    left             : 20px;
    z-index          : 1;
    width            : 60px;
    height           : 60px;
    display          : flex;
    align-items      : center;
    justify-content  : center;
    box-shadow       : 0 2px 2px rgba(0, 0, 0, 0.1);
    border-radius    : 5px;
    background-color : #6b737a;
    background-image : linear-gradient(to top, #414e5b 0%, #6b737a 100%);

    &:after {
      content          : '';
      border-radius    : 5px;
      background-color : #0054af;
      background-image : linear-gradient(to top, #00458f 0%, #0054af 100%);
      position         : absolute;
      top              : 0;
      right            : 0;
      bottom           : 0;
      left             : 0;
      z-index          : -1;
      opacity          : 0;
      transition       : all .2s ease-in-out;
    }
  }

  &__img {
    margin-bottom : 10px;
    height        : 210px;
    overflow      : hidden;
    border-radius : 3px 3px 0 0;
    transition    : all .2s ease-in-out;
    filter        : grayscale(0.8);

    img {
      max-width : 100%;
    }
  }

  &__info {
    padding       : 0 28px 0 19px;
    margin-bottom : 16px;
    min-height    : 122px;
  }

  &__description {
    color         : #ffffff;
    font-family   : $GothamPro;
    font-size     : 16px;
    font-weight   : 500;
    margin-bottom : 14px;
    line-height   : 20px;
  }

  &__h3 {
    color       : #ffcb05;
    font-size   : 12px;
    font-family : $GothamPro;
    margin      : 0;
  }

  &__link {
    display          : inline-block;
    margin-left      : 19px;
    padding          : 6px 14px;
    box-shadow       : 0 2px 2px rgba(0, 0, 0, 0.1);
    border-radius    : 3px;
    background-color : #a7abaf;
    background-image : linear-gradient(to top, #656d75 0%, #a7abaf 100%);
    color            : #ffffff;
    font-family      : $GothamPro;
    font-size        : 12px;
    font-weight      : 500;
    text-transform   : uppercase;
    position         : relative;

    &:before {
      content          : '';
      position         : absolute;
      top              : 0;
      right            : 0;
      bottom           : 0;
      left             : 0;
      opacity          : 0;
      transition       : all .2s ease-in-out;
      background-color : #ed0d00;
      border-radius    : 3px;
      background-image : linear-gradient(to top, #d9261c 0%, #ed0d00 100%);
    }

    span {
      position : relative;

      &:after {
        content    : '';
        position   : absolute;
        background : #fff;
        height     : 2px;
        left       : 0;
        bottom     : -1px;
        width      : 0;
        transition : all .2s ease-in-out;
      }
    }

    &:hover {
      color : white;

      span:after {
        content    : '';
        position   : absolute;
        background : #fff;
        height     : 2px;
        bottom     : -1px;
        width      : 100%;
        left       : 0;
        transition : all .2s ease-in-out;
      }
    }
  }
}