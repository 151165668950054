.our-clients {
  margin-bottom : 20px;

  .main-h2 {
    margin-bottom : 36px;
  }

  .carousel-cell {
    width  : 23%;
    height : 230px;
    margin : 0 20px;
  }

  .flickity-prev-next-button {
    top : -22%;
  }

  .carousel .flickity-prev-next-button.previous {
    right : 0;
    left  : auto;
  }

  .client-item {
    width : 194px;
  }

  .flickity-page-dots {
    display : none;
  }

}

.client-item {
  &__img {
    height        : 118px;
    text-align    : center;
    line-height   : 118px;
    margin-bottom : 10px;

    img {
      max-width  : 95%;
      max-height : 118px;
    }
  }

  &__title {
    color       : #46474a;
    font-family : $GothamPro;
    font-size   : 14px;
    font-weight : 400;
    position    : relative;
    padding-top : 25px;
    height      : 70px;
    overflow    : hidden;
    text-align  : center;

    &:before {
      content          : '';
      position         : absolute;
      top              : 0;
      left             : 0;
      right            : 0;
      height           : 2px;
      width            : 100%;
      background-color : #ecedee;
    }
  }
}